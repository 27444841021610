import React from "react"
//import { Link } from "gatsby"
//import Mailto from 'reactv16-mailto';

import Layout from "../components/Layout"
import { Container } from "react-bootstrap"


import "../styles/Globals.scss"


export default function NotFound() {
  
  return (
    <Layout>
        <Container>
        <h1>404 Page not found</h1>
        <p>Ups, wir konnten diese Seite nicht finden!</p>

        </Container>
      
    </Layout>
  )
}
